import React from 'react';

export default class GmailSVG extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return(
      <a target="_blank" href="mailto:arpitmathur19@gmail.com">
        <svg width="48" height="48" viewBox="0 0 20 20" className="fill-slate-600 hover:fill-orange-400" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_67_99)">
            <path d="M9.99988 0.399963C4.69794 0.399963 0.399902 4.69806 0.399902 10C0.399902 15.3019 4.69794 19.6 9.99988 19.6C15.3018 19.6 19.5999 15.3019 19.5999 10C19.5999 4.69806 15.3018 0.399963 9.99988 0.399963ZM6.2312 7H13.7508C14.1496 7 13.9443 7.51172 13.7273 7.64307C13.5103 7.77393 10.506 9.59009 10.394 9.65747C10.282 9.72485 10.137 9.75708 9.99121 9.75708C9.84534 9.75708 9.70038 9.72485 9.58844 9.65747C9.47644 9.59009 6.47211 7.77393 6.25507 7.64307C6.03809 7.51172 5.83276 7 6.2312 7ZM13.9999 12.5C13.9999 12.71 13.7482 13 13.5554 13H6.44434C6.25159 13 5.99988 12.71 5.99988 12.5C5.99988 12.5 5.99988 8.94556 5.99988 8.85327C5.99988 8.76147 5.99817 8.64185 6.17175 8.7439C6.41827 8.88843 9.43738 10.6689 9.58844 10.7578C9.73944 10.8467 9.84534 10.8574 9.99121 10.8574C10.137 10.8574 10.2429 10.8467 10.394 10.7578C10.545 10.6689 13.5815 8.88892 13.828 8.74438C14.0016 8.64233 13.9999 8.76196 13.9999 8.85376C13.9999 8.94604 13.9999 12.5 13.9999 12.5Z" />
          </g>
          <defs>
            <clipPath id="clip0_67_99">
              <rect width="20" height="20" />
            </clipPath>
          </defs>
        </svg>
      </a>      
    );
  }
}