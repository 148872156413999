import React from 'react';

export default class LinkedInSVG extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return(
      <a target="_blank" href="https://www.linkedin.com/in/arpitmathurinfo/">
        <svg width="48" height="48" viewBox="0 0 20 20" className="fill-slate-600 hover:fill-orange-400" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_67_101)">
            <path d="M9.9999 0.400024C4.6979 0.400024 0.399902 4.69802 0.399902 10C0.399902 15.302 4.6979 19.6 9.9999 19.6C15.3019 19.6 19.5999 15.302 19.5999 10C19.5999 4.69802 15.3019 0.400024 9.9999 0.400024ZM7.6499 13.979H5.7059V7.72302H7.6499V13.979ZM6.6659 6.95502C6.0519 6.95502 5.6549 6.52002 5.6549 5.98202C5.6549 5.43302 6.0639 5.01102 6.6909 5.01102C7.3179 5.01102 7.7019 5.43302 7.7139 5.98202C7.7139 6.52002 7.3179 6.95502 6.6659 6.95502ZM14.7499 13.979H12.8059V10.512C12.8059 9.70502 12.5239 9.15702 11.8209 9.15702C11.2839 9.15702 10.9649 9.52802 10.8239 9.88503C10.7719 10.012 10.7589 10.192 10.7589 10.371V13.978H8.8139V9.71803C8.8139 8.93703 8.7889 8.28402 8.7629 7.72202H10.4519L10.5409 8.59102H10.5799C10.8359 8.18302 11.4629 7.58102 12.5119 7.58102C13.7909 7.58102 14.7499 8.43802 14.7499 10.28V13.979Z" />
          </g>
          <defs>
            <clipPath id="clip0_67_101">
              <rect width="20" height="20"/>
            </clipPath>
          </defs>
        </svg>
      </a>      
    );
  }
}