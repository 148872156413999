import React from "react";

export default class GoogleScholarSVG extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <a
        target="_blank"
        href="https://scholar.google.com/citations?user=gOmOFJMAAAAJ&hl=en&oi=ao"
      >
        <svg
          width="48"
          height="48"
          viewBox="0 0 48 48"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_303_7)">
            <path
              d="M23.3141 16.3328C22.6445 16.3328 21.9177 16.6718 21.5001 17.2118C21.0609 17.759 20.9235 18.4712 20.9235 19.1552C20.9235 20.9264 21.9471 23.8633 24.1719 23.8633C24.8271 23.8633 25.5248 23.547 25.9424 23.1222C26.5328 22.5102 26.5907 21.6679 26.5907 21.1999C26.5907 19.2919 25.4741 16.3328 23.3141 16.3328Z"
              className="fill-slate-600 hover:fill-orange-400"
            />
            <path
              d="M24.5882 28.1186C24.3578 28.1186 22.9543 28.1689 21.8671 28.5433C21.2911 28.7593 19.6354 29.3866 19.6354 31.2658C19.6354 33.145 21.4209 34.4918 24.2001 34.4918C26.6985 34.4918 28.0166 33.2672 28.0166 31.6328C28.0166 30.2864 27.1671 29.5738 25.1943 28.1482C24.9855 28.1194 24.8546 28.1186 24.5882 28.1186Z"
              className="fill-slate-600 hover:fill-orange-400"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M24 0.959961C11.2752 0.959961 0.959961 11.2752 0.959961 24C0.959961 36.7248 11.2752 47.04 24 47.04C36.7248 47.04 47.04 36.7248 47.04 24C47.04 11.2752 36.7248 0.959961 24 0.959961ZM20.9601 13.9999H33.2001L32.466 14.6341C32.466 14.6485 32.4695 14.6627 32.473 14.677C32.4765 14.6912 32.4801 14.7055 32.4801 14.7199V18.521C32.9121 18.7658 33.2001 19.2271 33.2001 19.7599V24.0799C33.2001 24.8719 32.5521 25.5199 31.7601 25.5199C30.9681 25.5199 30.3201 24.8719 30.3201 24.0799V19.7599C30.3201 19.2271 30.6081 18.7658 31.0401 18.521V15.8505L28.4554 18.0682C28.7074 18.5722 28.8801 19.184 28.8801 19.9328C28.8801 21.8912 27.7862 22.8629 26.699 23.7269L26.6505 23.7763C26.3238 24.1085 25.9635 24.475 25.9635 25.0305C25.9635 25.6065 26.3603 25.9307 26.6483 26.1611L27.5765 26.9022C28.7213 27.8814 29.759 28.7819 29.759 30.6035C29.759 33.0875 27.39 35.5999 22.926 35.5999C19.1604 35.5999 17.346 33.7783 17.346 31.8199C17.346 30.8695 17.8135 29.5227 19.3471 28.5939C20.9338 27.6049 23.0835 27.4601 24.2665 27.3804L24.3083 27.3775L24.2559 27.3099C23.9025 26.8545 23.5236 26.3663 23.5236 25.5775C23.5236 25.1945 23.6272 24.943 23.7397 24.67C23.7562 24.63 23.7729 24.5896 23.7894 24.5482L23.788 24.5483C23.5005 24.5771 23.2129 24.6058 22.9541 24.6058C20.2181 24.6058 18.6555 22.5319 18.6411 20.4799H13.9999V20.264L20.9601 13.9999Z"
              className="fill-slate-600 hover:fill-orange-400"
            />
          </g>
          <defs>
            <clipPath id="clip0_303_7">
              <rect
                width="48"
                height="48"
                className="fill-slate-600 hover:fill-orange-400"
              />
            </clipPath>
          </defs>
        </svg>
      </a>
    );
  }
}
